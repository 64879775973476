import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import czJSON from "@/locale/cz.json";
// import deJSON from "@/locale/de.json";
import enJSON from "@/locale/en.json";
// import plJSON from "@/locale/pl.json";
import i18n from "i18next";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "cz",
    resources: {
      cz: { ...czJSON },
      en: { ...enJSON }
      // de: { ...deJSON },
      // pl: { ...plJSON }
    }
    // lng: "cz"
  });
