import { MantineProvider } from "@mantine/core";

import { AppShellContainer, CurrenciesTable, Footer } from "@/components";
import { versionUpdate } from "./utils/utils";
import { useEffect } from "react";
import "./App.css";

function App() {
  useEffect(() => {
    versionUpdate();
  }, []);

  return (
    <MantineProvider>
      <AppShellContainer>
        <CurrenciesTable />
        <Footer />
      </AppShellContainer>
    </MantineProvider>
  );
}

export default App;
