/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Group, NumberInput, Text } from "@mantine/core";

import { useMoneyObject } from "@/hooks/useMoneyObject/useMoneyObject";
import { handleFocus, withSeparator } from "@/utils/utils";
// import { currencies, CurrencySeed } from "@/utils/const";
import { Currency } from "@/hooks/useMoneyObject/types";
import { IconCircleFilled } from "@tabler/icons-react";
import { CurrencySeed } from "@/utils/const";
import { theme } from "@/theme/theme";
// import czk5000 from "@/assets/czk/5000.jpg";

interface DenominationRowProps {
  denomination: number;
  currency: string;
  index: number;
}

export const DenominationRow = (props: DenominationRowProps) => {
  const { currency, denomination, index } = props;
  const [amount, setAmount] = useState<number>(0);
  const {
    updateCurrency,
    currencies,
    resetSeed,
    displayUnits,
    getAmountFromStorage
  } = useMoneyObject();

  const [totalValueAmount, setTotalValueAmount] = useState<number>(0);

  const onChangeAmount = (amount: number | string) => {
    const newAmount = Number(amount);
    setAmount(newAmount);
    const totalAmount = denomination * newAmount;
    setTotalValueAmount(totalAmount);
    updateCurrency(currency, denomination, newAmount);
  };

  useEffect(() => {
    setAmount(0);
  }, [resetSeed[currency as keyof CurrencySeed], currency]);

  useEffect(() => {
    const storedAmount = getAmountFromStorage(
      currency as Currency,
      denomination
    );
    if (storedAmount > 0) {
      setAmount(storedAmount);
    }
  }, []);

  const c = theme.colors;
  const s = theme.spacing;

  const getCurrencyWithUnit = (value: number) => {
    if (!displayUnits) {
      return `${withSeparator(value)} `;
    }

    const getCurrencyWithSymbol = (unit: string, subunit?: string) => {
      return denomination < 1
        ? `${withSeparator(value * 100)} ${subunit} `
        : `${withSeparator(value)}  ${unit} `;
    };

    return getCurrencyWithSymbol(
      currencies[currency].unit,
      currencies[currency].subunit
    );
  };

  return (
    <Group
      style={{ borderRadius: theme.radius.denominationRow }}
      w={350}
      bg={index % 2 === 0 ? c.oddRows : c.evenRows}
      p={s.tableRows}
    >
      {/* {showImages ? (
        <Group gap={0} ta="right">
        <Image radius="xs" w={65} src={czk5000} />
        <Text> &nbsp;&times;</Text>
        </Group>
      ) : ( */}
      {amount > 0 && (
        <IconCircleFilled
          color={theme.colors.modifiedIndicator}
          style={{
            width: theme.width.indicator,
            marginLeft: theme.margin.indicatorLeft
          }}
        />
      )}

      <Text flex={1} ta="right" c={c.defaultText}>
        {getCurrencyWithUnit(denomination)}
        &times;
      </Text>
      <NumberInput
        allowDecimal={false}
        fw={amount > 0 ? theme.fonts.modifiedRow : theme.fonts.defaultRow}
        w={theme.width.amountInput}
        min={0}
        max={999}
        value={amount}
        onFocus={handleFocus}
        onChange={(e) => onChangeAmount(e)}
      />
      <Text w={theme.width.totalValueAmount} ta="right" c={c.defaultText}>
        {getCurrencyWithUnit(totalValueAmount)}
      </Text>
    </Group>
  );
};
