import { Conversions, Summary, ValuesTable } from "@/components";
import { Flex, Paper, Stack, Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useMoneyObject } from "@/hooks";
import { theme } from "@/theme/theme";
import { useState } from "react";

export const CurrenciesTable = () => {
  const { hasCurrencyChanged, currencies, baseCurrency } = useMoneyObject();
  const [selectedCurrencyTab, setSelectedCurrencyTab] = useState(baseCurrency);
  const { t } = useTranslation();

  const onChangeTab = (value: string | null) => {
    if (value !== null) {
      setSelectedCurrencyTab(value);
    }
  };

  // Component for individual currency tab
  const CurrencyTab = ({ currency }: { currency: string }) => (
    <Tabs.Tab
      value={currency}
      title={t(`currencies.${currency.toLowerCase()}`)}
      fw={hasCurrencyChanged(currency) ? 500 : 300}
      c={
        hasCurrencyChanged(currency)
          ? theme.colors.changedCurrency
          : theme.colors.defaultText
      }
    >
      {currencies[currency].unit}
    </Tabs.Tab>
  );

  return (
    <Paper
      shadow="xl"
      withBorder
      px={theme.padding.paperX}
      py={theme.padding.paperTop}
      bg={theme.colors.defaultBackground}
    >
      <Flex gap={12}>
        <Tabs
          value={selectedCurrencyTab}
          onChange={onChangeTab}
          c={theme.colors.defaultText}
          radius="sm"
          variant="outline"
        >
          <Tabs.List>
            {Object.entries(currencies)
              .sort(([, a], [, b]) => a.sortOrder - b.sortOrder)
              .map(([currencyCode]) => (
                <CurrencyTab key={currencyCode} currency={currencyCode} />
              ))}
          </Tabs.List>

          {Object.entries(currencies).map(([currencyCode]) => (
            <Tabs.Panel mt={2} key={currencyCode} value={currencyCode}>
              <ValuesTable currency={currencyCode} />
            </Tabs.Panel>
          ))}
        </Tabs>
        <Stack gap={4}>
          <Summary currency={selectedCurrencyTab} />
          <Conversions />
        </Stack>
      </Flex>
    </Paper>
  );
};
