import { BACKUP_RATES, CurrencySeed, KEY, moneyObject } from "@/utils/const";
import { ConversionRates, Currency, Money } from "@/types";
import { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";

// Atoms
import {
  baseCurrencyAtom,
  conversionRatesAtom,
  currenciesAtom,
  displayUnitsAtom,
  moneyAtom,
  resetSeedAtom,
  showImagesAtom
} from "@/store/atoms";

// Custom Hook
export const useMoneyObject = () => {
  const [conversionRates, setConversionRates] = useAtom(conversionRatesAtom);
  const [money, setMoney] = useAtom(moneyAtom);
  const [baseCurrency, setBaseCurrency] = useAtom(baseCurrencyAtom);
  const [showImages, setShowImages] = useAtom(showImagesAtom);
  const [displayUnits, setDisplayUnits] = useAtom(displayUnitsAtom);
  const [resetSeed, setResetSeed] = useAtom(resetSeedAtom);
  const [currencies, setCurrencies] = useAtom(currenciesAtom);

  const [opened, setOpened] = useState(false); // For modal control
  const [exportData, setExportData] = useState(""); // Holds the export data

  const prevBaseCurrency = useRef(baseCurrency);

  useEffect(() => {
    localStorage.setItem(KEY.currenciesAmount, JSON.stringify(money));
  }, [money]);

  useEffect(() => {
    const storedCurrencies = localStorage.getItem(KEY.currenciesAmount);
    if (storedCurrencies) {
      try {
        setMoney(JSON.parse(storedCurrencies));
      } catch (error) {
        console.error("Error parsing currencies from localStorage:", error);
      }
    }
  }, [setMoney]);

  const resetConversionRates = () => setConversionRates(BACKUP_RATES);

  const updateConversionRate = (currency: string, newRate: number) => {
    setConversionRates((prevRates: ConversionRates) => {
      const updatedRates = { ...prevRates, [currency]: newRate };
      localStorage.setItem(KEY.conversionRates, JSON.stringify(updatedRates));
      return updatedRates;
    });
  };

  const toggleImagesDisplay = () => setShowImages((prev) => !prev);
  const toggleDisplayUnits = () => setDisplayUnits((prev) => !prev);

  const changeBaseCurrency = (currency: string) => {
    const prevBaseCurrencyEntry = Object.entries(currencies).find(
      ([, value]) => value.sortOrder === 0
    );

    if (prevBaseCurrencyEntry) {
      setCurrencies((prevCurrencies) => ({
        ...prevCurrencies,
        [prevBaseCurrencyEntry[0]]: {
          ...prevBaseCurrencyEntry[1],
          sortOrder: currencies[currency].sortOrder
        },
        [currency]: {
          ...currencies[currency],
          sortOrder: 0
        }
      }));
    }

    setBaseCurrency(currency);
  };

  useEffect(() => {
    if (prevBaseCurrency.current !== baseCurrency) {
      const baseCurrencyRate = conversionRates[baseCurrency];
      const newConversionRates = Object.fromEntries(
        Object.entries(conversionRates).map(([currency, rate]) => [
          currency,
          (rate as number) / baseCurrencyRate
        ])
      );

      setConversionRates(newConversionRates);
      prevBaseCurrency.current = baseCurrency;
    }
  }, [baseCurrency, conversionRates, setConversionRates]);

  const getAmountFromStorage = (
    currency: Currency,
    denomination: number
  ): number => money[currency]?.[denomination] || 0;

  const updateCurrency = (
    currency: string,
    denomination: number,
    amount: number
  ) => {
    setMoney((prevMoney: Money) => ({
      ...prevMoney,
      [currency]: {
        ...prevMoney[currency],
        [denomination]: amount
      }
    }));
  };

  const resetCurrency = (currency?: keyof CurrencySeed) => {
    setMoney((prevMoney: Money) => {
      if (!currency) {
        // Reset all currencies
        const resetAll = Object.fromEntries(
          Object.entries(prevMoney).map(([currency, denominations]) => [
            currency,
            Object.fromEntries(
              Object.entries(denominations).map(([denomination]) => [
                denomination,
                0
              ])
            )
          ])
        ) as Money;
        return resetAll;
      } else {
        // Reset the specified currency
        const resetDenominations = Object.fromEntries(
          Object.entries(prevMoney[currency]).map(([denomination]) => [
            denomination,
            0
          ])
        );

        return {
          ...prevMoney,
          [currency]: resetDenominations
        };
      }
    });

    setResetSeed((prevSeed: CurrencySeed) => {
      if (!currency) {
        // Reset all seeds with all required properties of CurrencySeed
        const resetAllSeeds: CurrencySeed = {
          CHF: Math.random(),
          CZK: Math.random(),
          EUR: Math.random(),
          GBP: Math.random(),
          PLN: Math.random(),
          RUB: Math.random(),
          UAH: Math.random(),
          USD: Math.random()
        };
        return resetAllSeeds;
      } else {
        // Reset the seed for the specified currency, making sure to keep the others unchanged
        return {
          ...prevSeed,
          [currency]: Math.random() // We know currency is a key of CurrencySeed
        };
      }
    });
  };

  const getTotalMoney = (currency?: string, convertTo?: string): number => {
    const calculateTotalForCurrency = (curr: string) =>
      Object.entries(money[curr] || {}).reduce(
        (sum, [denomination, amount]) =>
          sum + Number(denomination) * (amount as number),
        0
      );

    const convertCurrencyTotal = (
      amount: number,
      fromCurrency: string,
      toCurrency: string
    ) => {
      const fromRate = conversionRates[fromCurrency];
      const toRate = conversionRates[toCurrency];
      return (amount * fromRate) / toRate || 0;
    };

    let total = 0;

    if (!currency && !convertTo) {
      convertTo = baseCurrency;
      Object.keys(money).forEach((curr) => {
        total += convertCurrencyTotal(
          calculateTotalForCurrency(curr),
          curr,
          convertTo!
        );
      });
    } else if (currency && !convertTo) {
      total = calculateTotalForCurrency(currency);
    } else if (currency && convertTo) {
      total = convertCurrencyTotal(
        calculateTotalForCurrency(currency),
        currency,
        convertTo
      );
    }

    return total;
  };

  const hasCurrencyChanged = (currency: string): boolean =>
    Object.keys(moneyObject[currency] as Record<string, number>).some(
      (denomination) =>
        (moneyObject[currency] as Record<string, number>)[denomination] !==
        (money[currency] as Record<string, number>)[denomination]
    );

  const generateExportData = () => {
    const formattedData = Object.entries(money)
      .map(
        ([currency, denominations]) =>
          `Currency: ${currency}\n` +
          Object.entries(denominations)
            .map(
              ([denomination, amount]) =>
                `Denomination: ${denomination} - Amount: ${amount}`
            )
            .join("\n") +
          "\n------------------------"
      )
      .join("\n");

    setExportData(formattedData);
    setOpened(true);
  };

  return {
    baseCurrency,
    getTotalMoney,
    hasCurrencyChanged,
    setMoney,
    changeBaseCurrency,
    currencies,
    money,
    resetCurrency,
    resetSeed,
    toggleImagesDisplay,
    getAmountFromStorage,
    displayUnits,
    toggleDisplayUnits,
    showImages,
    updateCurrency,
    generateExportData,
    exportData,
    opened,
    setOpened,
    conversionRates,
    updateConversionRate,
    resetConversionRates
  };
};
