import { useTranslation } from "react-i18next";
import { Button, Menu } from "@mantine/core";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Menu>
      <Menu.Target>
        <Button variant="transparent" c="white">
          {i18n.language.toUpperCase()}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={() => changeLanguage("en")}>en</Menu.Item>
        <Menu.Item onClick={() => changeLanguage("cz")}>cz</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
