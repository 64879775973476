import { useTranslation } from "react-i18next";
import { atomWithStorage } from "jotai/utils";
import { KEY } from "@/utils/const";
import { useAtom } from "jotai";

// Check if the app is running in development environment
const isDevelopment = process.env.NODE_ENV === "development";

const debugAtom = atomWithStorage(
  KEY.debug,
  isDevelopment ? true : false,
  undefined,
  {
    getOnInit: true
  }
);

export const useSettings = () => {
  const { i18n } = useTranslation();
  const [debugEnabled] = useAtom<boolean>(debugAtom);

  return {
    debugEnabled,
    isDevelopment,
    displayDate: new Date().toLocaleDateString(i18n.language, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    })
  };
};
