import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const ErrorFallback = (props: { errorMessage: string }) => {
  const errorMessage = props.errorMessage;
  const [countdown, setCountdown] = useState<number | null>(null);
  const secondsUntilReload = 5;
  const progressBarWidth = 300;
  const { t } = useTranslation();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (countdown !== null) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(intervalId);
            setTimeout(() => {
              restartApp();
            }, 1000);
          }
          return prevCountdown !== null && prevCountdown > 0
            ? prevCountdown - 1
            : 0;
        });
      }, 500);
    }

    return () => clearInterval(intervalId);
  }, [countdown]);

  useEffect(() => {
    if (countdown === null) {
      setCountdown(secondsUntilReload);
    }
  }, [countdown]);

  const restartApp = () => {
    localStorage.clear();
    window.location.reload();
  };

  const getProgressBarWidth = (countdown: number | null) => {
    if (countdown === null) return "0%";
    const percentage =
      ((secondsUntilReload - countdown) / secondsUntilReload) * 100;
    return `${(percentage * progressBarWidth) / 100}px`;
  };

  return (
    <div style={styles.container}>
      <h1>{t("errorBoundary.errorMessage")}</h1>
      <p>{errorMessage}</p>
      <p>{t("errorBoundary.reloadingMessage")}</p>
      <div style={styles.progressContainer}>
        <div
          style={{
            ...styles.progressBar,
            width: getProgressBarWidth(countdown)
          }}
        />
      </div>
      <button style={styles.button} onClick={() => restartApp()}>
        {t("errorBoundary.reloadButton")}
      </button>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#2e2e2e",
    color: "#fff",
    width: "100vw",
    height: "100vh", // Full height to fill the entire viewport
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center" as const,
    padding: "20px"
  },
  button: {
    backgroundColor: "#4caf50",
    borderRadius: "10px",
    padding: "10px 20px",
    color: "#fff",
    cursor: "pointer",
    marginTop: "20px"
  },
  progressContainer: {
    width: "300px",
    height: "20px",
    backgroundColor: "#555",
    borderRadius: "5px",
    overflow: "hidden",
    marginTop: "20px"
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#4caf50",
    transition: "width 1s linear"
  }
};
