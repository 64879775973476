export const theme = {
  colors: {
    appTitle: "#d8d2e1",
    appTitleWhite: "blue.9",
    borderColor: "blue.2",
    changedCurrency: "#ffee00",
    conversionsBackground: "blue.4",
    defaultBackground: "blue.9",
    defaultDevBackground: "#3b3979",
    defaultText: "white",
    evenRows: "blue.5",
    footerBackground: "#34435e",
    grandTotalBackground: "blue.5",
    modifiedIndicator: "#00ee00",
    oddRows: "blue.7",
    summary: "blue.3"
  },
  fonts: {
    defaultRow: 400,
    modifiedRow: 750
  },
  margin: {
    indicatorLeft: 10,
    summaryTop: 34
  },
  padding: {
    exportPaper: 10,
    footer: 0,
    grandTotal: 10,
    summary: { x: 26, y: 10 },
    paperX: 20,
    paperTop: 10,
    paperBottom: 20
  },
  spacing: {
    tableRows: 5
  },
  radius: {
    denominationRow: 6
  },
  width: {
    totalValueAmount: 100,
    amountInput: 80,
    summary: 250,
    indicator: 10
  }
};
