import { Button, Paper, Stack, Text, Title } from "@mantine/core";

import { ExportButton } from "../ExportButton/ExportButton";
import { printFormattedCurrency } from "@/utils/utils";
import { useTranslation } from "react-i18next";
import { CurrencySeed } from "@/utils/const";
import { useMoneyObject } from "@/hooks";
import { theme } from "@/theme/theme";

interface SummaryProps {
  currency: string;
}

export const Summary = (props: SummaryProps) => {
  const { currency } = props;
  const { t } = useTranslation();
  const { resetCurrency, getTotalMoney, baseCurrency, changeBaseCurrency } =
    useMoneyObject();

  return (
    <Stack gap={4}>
      <Paper
        bg={theme.colors.summary}
        w={theme.width.summary}
        shadow="sm"
        withBorder
        px={theme.padding.summary.x}
        py={theme.padding.summary.y}
        mt={theme.margin.summaryTop}
      >
        <Stack gap={4}>
          <Text c={theme.colors.defaultText} size={"xl"}>
            {t("label.total")}
          </Text>
          <Title c={theme.colors.defaultText} order={1}>
            {printFormattedCurrency(getTotalMoney(currency), currency)}
          </Title>
          <Button onClick={() => resetCurrency(currency as keyof CurrencySeed)}>
            {t("button.reset")} {currency}
          </Button>{" "}
          <Button onClick={() => changeBaseCurrency(currency)}>
            {t("button.defaultCurrency")}
          </Button>
          <ExportButton />
        </Stack>
      </Paper>
      <Paper
        bg={theme.colors.grandTotalBackground}
        w={theme.width.summary}
        shadow="sm"
        withBorder
        px={theme.padding.summary.x}
        py={theme.padding.summary.y}
      >
        <Stack>
          <Text c={theme.colors.defaultText} size={"xl"}>
            {t("label.total")}
          </Text>
          <Title c={theme.colors.defaultText} order={1}>
            {printFormattedCurrency(getTotalMoney(), baseCurrency)}
          </Title>
          <Button onClick={() => resetCurrency()}>
            {t("button.resetAll")}
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
};
