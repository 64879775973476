import { useTranslation } from "react-i18next";
import { useMoneyObject } from "@/hooks";
import { Button } from "@mantine/core";
import * as XLSX from "xlsx";

export const ExportButton = () => {
  const { t } = useTranslation();
  const { money: moneyObject } = useMoneyObject();

  const handleExport = () => {
    const workbook = XLSX.utils.book_new(); // Create a new workbook

    // Iterate over each currency and create a separate sheet
    for (const currency in moneyObject) {
      const currencyData = moneyObject[currency];
      const data = [];

      // Get denominations as an array and sort them from largest to smallest
      const sortedDenominations = Object.keys(currencyData)
        .map(Number) // Convert string keys to numbers
        .sort((a, b) => b - a); // Sort in descending order

      // Populate the data array with sorted denominations
      for (const denomination of sortedDenominations) {
        data.push({
          Denomination: denomination,
          Count: currencyData[denomination]
        });
      }

      // Create a worksheet for the current currency
      const worksheet = XLSX.utils.json_to_sheet(data);

      // Append the worksheet to the workbook with the currency name as the sheet name
      XLSX.utils.book_append_sheet(workbook, worksheet, currency);
    }

    // Export the workbook to a file
    const fileName = `mincovka-${new Date().toISOString().slice(0, 10)}`;
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <>
      <Button onClick={handleExport}>{t("button.export")}</Button>
    </>
  );
};
