import { Button, Group, NumberInput, Paper, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { handleFocus } from "@/utils/utils";
import { currencies } from "@/utils/const";
import { useMoneyObject } from "@/hooks";
import { theme } from "@/theme/theme";

const currencyConversions = ["EUR", "PLN", "GBP", "USD", "CHF", "UAH", "CZK"];

export const Conversions = () => {
  const {
    conversionRates,
    baseCurrency,
    updateConversionRate,
    resetConversionRates
  } = useMoneyObject();
  const { t } = useTranslation();

  // Configuration for NumberInput components
  const conversionInputProps = {
    w: 95,
    onFocus: handleFocus,
    rightSection: currencies[baseCurrency].unit
  };

  // Exclude baseCurrency from displayed conversions
  const displayedConversions = currencyConversions.filter(
    (currency) => currency !== baseCurrency
  );

  // Render a NumberInput for a specific currency
  const renderConversionInput = (currency: string) => (
    <NumberInput
      key={currency}
      {...conversionInputProps}
      onChange={(e) => updateConversionRate(currency, Number(e))}
      leftSection={"1" + currencies[currency].unit}
      value={conversionRates[currency].toFixed(2)}
      title={t(`currencies.${currency.toLowerCase()}`)}
    />
  );

  return (
    <Paper
      bg={theme.colors.conversionsBackground}
      w={theme.width.summary}
      shadow="sm"
      withBorder
      p={5}
    >
      <Stack align="center" px={21} gap={4}>
        <Group gap={5}>{displayedConversions.map(renderConversionInput)}</Group>
        <Button fullWidth mx={10} bg="cyan.9" onClick={resetConversionRates}>
          {t("button.default")}
        </Button>
      </Stack>
      <Text size="xs" my={2} c={theme.colors.defaultText}>
        {t("label.conversionInfo")}
      </Text>
    </Paper>
  );
};
