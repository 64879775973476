import { appVersion } from "./appVersion";
import { KEY } from "./const";

const convertTimestampToHumanReadable = (timestamp: number) => {
  // return date in format "DD. MM. YYYY HH:mm" based on timestamp
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}. ${month}. ${year} ${hours}:${minutes}`;
};

const getDateKey = (): string => {
  const date = new Date();

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const dateKey = `${year}${month}${day}`;

  return dateKey;
};

const getTimeOfDay = (): string => {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
};

const isMorning = (): boolean => {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return true;
  } else {
    return false;
  }
};

const printFormattedCurrency = (value: number, currency: string) => {
  return Intl.NumberFormat("cs-CZ", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 2
  }).format(value);
};

const withSeparator = (value: number) => {
  return Intl.NumberFormat("cs-CZ").format(value);
};

const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) =>
  event.target.select();

const versionUpdate = () => {
  const version = localStorage.getItem(KEY.version);
  if (version !== appVersion) {
    localStorage.clear();
    localStorage.setItem(KEY.version, appVersion);
    console.log("Version updated to", appVersion);
  }
};

// Generics example
const getValue = <T>(key: string, defaultValue: T): T => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : defaultValue;
};

export {
  convertTimestampToHumanReadable,
  getDateKey,
  getTimeOfDay,
  handleFocus,
  isMorning,
  printFormattedCurrency,
  versionUpdate,
  withSeparator
};
