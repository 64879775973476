import { Stack } from "@mantine/core";

import { DenominationRow } from "@/components";
import { useMoneyObject } from "@/hooks";

interface ValuesTableProps {
  currency: string;
}

export const ValuesTable = (props: ValuesTableProps) => {
  const { currency } = props;
  const { money } = useMoneyObject();

  let moneyValues = [];

  switch (currency) {
    case "CZK":
      moneyValues = Object.keys(money.CZK)
        .map(Number)
        .sort((a, b) => b - a);
      break;
    case "EUR":
      moneyValues = Object.keys(money.EUR)
        .map(Number)
        .sort((a, b) => b - a);
      break;
    case "PLN":
      moneyValues = Object.keys(money.PLN)
        .map(Number)
        .sort((a, b) => b - a);
      break;
    case "USD":
      moneyValues = Object.keys(money.USD)
        .map(Number)
        .sort((a, b) => b - a);
      break;
    case "GBP":
      moneyValues = Object.keys(money.GBP)
        .map(Number)
        .sort((a, b) => b - a);
      break;
    case "CHF":
      moneyValues = Object.keys(money.CHF)
        .map(Number)
        .sort((a, b) => b - a);
      break;
    case "UAH":
      moneyValues = Object.keys(money.UAH)
        .map(Number)
        .sort((a, b) => b - a);
      break;
    // case "RUB":
    //   moneyValues = Object.keys(money.RUB)
    //     .map(Number)
    //     .sort((a, b) => b - a);
    //   break;
    default:
      moneyValues = Object.keys(money.CZK)
        .map(Number)
        .sort((a, b) => b - a);
      break;
  }

  return (
    <Stack gap={4}>
      {moneyValues.map((value, index) => (
        <DenominationRow
          key={index}
          denomination={value}
          index={index}
          currency={currency}
        />
      ))}
    </Stack>
  );
};
