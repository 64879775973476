import { withTranslation } from "react-i18next";
import { Component } from "react";

interface ErrorBoundaryProps {
  t: (key: string, options?: Record<string, unknown>) => string;
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  countdown: number | null;
  errorMessage: string | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  secondsUntilReload: number;
  progressBarWidth: number; // New constant to define progress bar width

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.secondsUntilReload = 10; // Change to whatever countdown you need
    this.progressBarWidth = 300; // Progress bar width in pixels

    this.state = {
      hasError: false,
      countdown: null,
      errorMessage: null
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
  }

  restartApp() {
    localStorage.clear();
    window.location.reload();
  }

  startCountdown(seconds: number) {
    this.setState({ countdown: seconds });

    const intervalId = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.countdown === 1) {
          clearInterval(intervalId);
          setTimeout(() => {
            this.restartApp(); // Reload after progress bar finishes
          }, 1000); // Wait 1 second after countdown reaches 0
        }

        return {
          countdown:
            prevState.countdown !== null && prevState.countdown > 0
              ? prevState.countdown - 1
              : 0
        };
      });
    }, 1000);
  }

  getProgressBarWidth(countdown: number | null) {
    if (countdown === null) return "0%";
    const percentage =
      ((this.secondsUntilReload - countdown) / this.secondsUntilReload) * 100;
    return `${(percentage * this.progressBarWidth) / 100}px`;
  }

  render() {
    const { t } = this.props;
    const { countdown, errorMessage, hasError } = this.state;

    if (hasError) {
      if (countdown !== null) {
        return (
          <div style={styles.container}>
            <h1>{t("errorBoundary.errorMessage")}</h1>
            <p>{errorMessage}</p>
            <p>{t("errorBoundary.reloadingMessage")}</p>
            <div style={styles.progressContainer}>
              <div
                style={{
                  ...styles.progressBar,
                  width: this.getProgressBarWidth(countdown)
                }}
              />
            </div>
            <button style={styles.button} onClick={() => this.restartApp()}>
              {t("errorBoundary.reloadButton")}
            </button>
          </div>
        );
      }

      // Start the countdown and progress bar
      this.startCountdown(this.secondsUntilReload);

      return (
        <div style={styles.container}>
          <h1>{t("errorBoundary.errorMessage")}</h1>
          <p>{t("errorBoundary.reloadingSoon")}</p>
          <div style={styles.progressContainer}>
            <div style={{ ...styles.progressBar, width: "0%" }} />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const styles = {
  container: {
    backgroundColor: "#2e2e2e",
    color: "#fff",
    width: "100vw",
    height: "100vh", // Full height to fill the entire viewport
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center" as const,
    padding: "20px"
  },
  button: {
    backgroundColor: "#4caf50",
    borderRadius: "10px",
    padding: "10px 20px",
    color: "#fff",
    cursor: "pointer",
    marginTop: "20px"
  },
  progressContainer: {
    width: "300px",
    height: "20px",
    backgroundColor: "#555",
    borderRadius: "5px",
    overflow: "hidden",
    marginTop: "20px"
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#4caf50",
    transition: "width 1s linear"
  }
};

const TranslatedErrorBoundary = withTranslation()(ErrorBoundary);
export { TranslatedErrorBoundary };
