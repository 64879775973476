import { NavBar } from "../NavBar/NavBar";
import { AppShell } from "@mantine/core";
import { theme } from "@/theme/theme";
import { useSettings } from "@/hooks";

export const AppShellContainer = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { isDevelopment } = useSettings();

  return (
    <AppShell header={{ height: 35 }}>
      <AppShell.Header
        bg={
          isDevelopment
            ? theme.colors.defaultDevBackground
            : theme.colors.defaultBackground
        }
      >
        <NavBar />
      </AppShell.Header>
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
};
