import {
  BACKUP_RATES,
  currencies,
  currencySeed,
  CurrencySeed,
  KEY,
  moneyObject
} from "@/utils/const";
import { atomWithStorage } from "jotai/utils";
import { atom } from "jotai";

const conversionRatesAtom = atomWithStorage(
  KEY.conversionRates,
  BACKUP_RATES,
  undefined,
  { getOnInit: true }
);
const moneyAtom = atomWithStorage(
  KEY.currenciesAmount,
  moneyObject,
  undefined,
  { getOnInit: true }
);
const currenciesAtom = atomWithStorage(KEY.currencies, currencies, undefined, {
  getOnInit: true
});
const baseCurrencyAtom = atomWithStorage(KEY.baseCurrency, "CZK", undefined, {
  getOnInit: true
});
const resetSeedAtom = atom<CurrencySeed>(currencySeed);
const showImagesAtom = atom(false);
const displayUnitsAtom = atom(true);

//export all atoms
export {
  baseCurrencyAtom,
  conversionRatesAtom,
  currenciesAtom,
  displayUnitsAtom,
  moneyAtom,
  resetSeedAtom,
  showImagesAtom
};
