import { IconCheck, IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { rem, Switch } from "@mantine/core";
import { useMoneyObject } from "@/hooks";

export const UnitSwitch = () => {
  const { toggleDisplayUnits, displayUnits } = useMoneyObject();
  const { t } = useTranslation();

  return (
    <Switch
      visibleFrom="sm"
      c="white"
      my={4}
      checked={displayUnits}
      label={t("switch.showUnits")}
      ta="right"
      onChange={toggleDisplayUnits}
      thumbIcon={
        displayUnits ? (
          <IconCheck
            style={{ width: rem(12), height: rem(12) }}
            color="green"
            stroke={3}
          />
        ) : (
          <IconX
            style={{ width: rem(12), height: rem(12) }}
            color="orange"
            stroke={3}
          />
        )
      }
    />
  );
};
