import { SupportLinks } from "../SupportLinks/SupportLinks";
import { appVersion } from "@/utils/appVersion";
import { useTranslation } from "react-i18next";
import { Group, Text } from "@mantine/core";
import { theme } from "@/theme/theme";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Group justify="center" my={2}>
      <Text size="sm" c={theme.colors.appTitleWhite}>
        {t("appTitle", { appVersion })}
      </Text>
      <SupportLinks />
    </Group>
  );
};
