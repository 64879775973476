import { createRoot } from "react-dom/client";
import { StrictMode } from "react";

import "@mantine/dates/styles.css";
import "@mantine/core/styles.css";

import { ErrorFallback } from "./components/index.ts";
import * as Sentry from "@sentry/react";
import App from "./App.tsx";
import "./index.css";
import "./i18n.ts";

Sentry.init({
  dsn: "https://d6dbb624e7c0beeb56a28aea46e195ae@o4508117693693952.ingest.de.sentry.io/4508117846327376",
  enabled: process.env.NODE_ENV === "production",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
    // Sentry.feedbackIntegration({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: "system"
    // })
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <ErrorFallback errorMessage={(error as Error).toString()} />
      )}
    >
      <App />
    </Sentry.ErrorBoundary>
  </StrictMode>
);
