// import GooglePayButton from "@google-pay/button-react";
import { useTranslation } from "react-i18next";
import { KoFiButton } from "react-kofi";
import { Flex } from "@mantine/core";
import "react-kofi/dist/styles.css";

export const SupportLinks = () => {
  const { t } = useTranslation();

  // const goToLink = () => {
  //   window.open(
  //     "https://www.buymeacoffee.com/badmuggames",
  //     "_blank" // This opens the link in a new window
  //   );
  // };

  // const BuyMeACoffeeButton = () => {
  //   return (
  //     <Button
  //       leftSection={
  //         <Image
  //           src="https://cdn.buymeacoffee.com/buttons/v2/default-green.png"
  //           style={{ width: "150px", borderRadius: "8px" }}
  //         />
  //       }
  //       variant="transparent"
  //       onClick={goToLink}
  //     />
  //   );
  // };

  // const GooglePayButtonComponent = () => {
  //   const paymentRequest: google.payments.api.PaymentDataRequest = {
  //     apiVersion: 2,
  //     apiVersionMinor: 0,
  //     allowedPaymentMethods: [
  //       {
  //         type: "CARD", // This should be correct as per Google Pay API
  //         parameters: {
  //           allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"], // Ensure correct values here
  //           allowedCardNetworks: ["MASTERCARD", "VISA"] // Ensure correct values here
  //         },
  //         tokenizationSpecification: {
  //           type: "PAYMENT_GATEWAY",
  //           parameters: {
  //             gateway: "example", // Replace with actual payment gateway
  //             gatewayMerchantId: "exampleMerchantId" // Replace with actual merchant ID
  //           }
  //         }
  //       }
  //     ],
  //     merchantInfo: {
  //       merchantId: "BCR2DN4TSP2YXMZZ", // Replace with actual merchant ID
  //       merchantName: "Bad Mug Games"
  //     },
  //     transactionInfo: {
  //       totalPriceStatus: "FINAL",
  //       totalPriceLabel: "Total",
  //       totalPrice: "10.00",
  //       currencyCode: "EUR",
  //       countryCode: "CZ"
  //     }
  //   };

  //   return (
  //     <GooglePayButton
  //       environment="PRODUCTION"
  //       buttonColor="black"
  //       buttonType="donate"
  //       paymentRequest={paymentRequest}
  //       onLoadPaymentData={(paymentRequest: unknown) => {
  //         console.log("Payment data loaded", paymentRequest);
  //       }}
  //     />
  //   );
  // };

  return (
    <Flex justify={"center"} py={0} gap={4}>
      <KoFiButton
        color="#00b4f7"
        textColor="#fff"
        id="robertsibek"
        label={t("button.kofi")}
        padding={0}
        width={400}
        iframe={false}
        buttonRadius="8px"
      />
      {/* <BuyMeACoffeeButton /> */}
      {/* <GooglePayButtonComponent /> */}
    </Flex>
  );
};
